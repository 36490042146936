import React, { useState, useEffect } from "react"
import scrollStyles from "./scroll.module.css"
import ArrowUp from "../images/arrow-up-solid.svg"

const Scroll = () => {
  const [show, setShow] = useState(false)

  const handleScroll = () => {
    if (window.pageYOffset > 100) {
      if (!show) setShow(true)
      document.getElementById("scroll").style.display = "flex"
      document.getElementById("scroll").style.justifyContent = "center"
      document.getElementById("scroll").style.alignItems = "center"
    } else {
      if (show) setShow(false)
      document.getElementById("scroll").style.display = "none"
    }
  }

  const handleClick = () => {
    document.getElementById("scroll").style.backgroundColor = "#0f2765"
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    window.addEventListener(`scroll`, handleScroll)
    return () => window.removeEventListener(`scroll`, handleScroll)
  })

  return (
    <button className={scrollStyles.scroll} id="scroll" onClick={handleClick}>
      <img src={ArrowUp} alt="Scroll to Top" />
    </button>
  )
}

export default Scroll
