import React from "react"

import Container from "react-bootstrap/Container"
import footerStyles from "./footer.module.css"

const Footer = ({ siteTitle }) => (
  <footer className={footerStyles.footer}>
    <Container>
      <p>{`${new Date().getFullYear()} © ${siteTitle}`}</p>
    </Container>
  </footer>
)
export default Footer
