import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import NavDropdown from "react-bootstrap/NavDropdown"
import Container from "react-bootstrap/Container"
import headerStyles from "./header.module.css"
import Logo from "./images/sailogo"
import "./carousel.css"

const Header = ({ siteTitle }) => (
  <header>
    <Navbar fixed="top" className={headerStyles.headerNav} expand="md">
      <Container>
        <Navbar.Brand style={{ color: "#FFF", textShadow: "1px 1px 2px #000" }} >
          <Link to="/">
            <Logo />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          id={headerStyles.toggler}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Link
              className={headerStyles.navLinks}
              activeClassName={headerStyles.linkActive}
              to="/"
            >
              Home
            </Link>
            <NavDropdown title="Learn" id="basic-nav-dropdown" style={{ textAlign: "center" }}>
              <NavDropdown.Item><Link to="/what-we-do/">What we do</Link></NavDropdown.Item>
              <NavDropdown.Item><Link to="/what-we-do/#core-value">Core Values</Link></NavDropdown.Item>
              <NavDropdown.Item><Link to="/what-we-do/#client-value">Client Value Cycle</Link></NavDropdown.Item>
              <NavDropdown.Item><Link to="/what-we-do/#approach">Approach</Link></NavDropdown.Item>
              <NavDropdown.Item><Link to="/what-we-do/#value-proposition">Value Proposition</Link></NavDropdown.Item>
              <NavDropdown.Item><Link to="/what-we-do/#strategic-diamond">Strategic Business Diamond</Link></NavDropdown.Item>
            </NavDropdown>
            <Link
              className={headerStyles.navLinks}
              activeClassName={headerStyles.linkActive}
              to="/services/"
            >
              Services
            </Link>
            <Link
              className={headerStyles.navLinks}
              activeClassName={headerStyles.linkActive}
              to="/about/"
            >
              About
            </Link>
            <NavDropdown title="Clients" style={{ textAlign: "center" }}>
              <NavDropdown.Item href="https://analytics.sainc.biz" target="_blank" rel="noreferrer" style={{ color: '#FFF' }}>Analytics</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
